import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"shaped":""}},[_c(VToolbar,{attrs:{"dense":"","flat":""}},[_c(VBtn,{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.goBack()}}},[_c(VIcon,[_vm._v("mdi-arrow-left-bold")])],1),_c(VSpacer),_c(VSwitch,{staticStyle:{"padding-top":"25px"},attrs:{"inset":"","color":"green darken-2","disabled":_vm.loading,"loading":_vm.loadingSwitch},on:{"click":function($event){$event.preventDefault();return _vm.activarEmpresa()}},model:{value:(_vm.empresa.activa),callback:function ($$v) {_vm.$set(_vm.empresa, "activa", $$v)},expression:"empresa.activa"}})],1),_c(VCol,{staticClass:"justify-center text-center"},[(_vm.empresa.logo)?_c(VImg,{attrs:{"src":`${_vm.thumbsURL}/${_vm.empresa.logo}`,"alt":"user-avatar","contain":"","max-height":"300"}}):_c(VAvatar,{attrs:{"size":"250"}},[_c(VIcon,{attrs:{"size":"230"}},[_vm._v("mdi-office-building")])],1)],1),(_vm.loading)?_c(VSheet,{staticClass:"pa-3"},[_c(VSkeletonLoader,{staticClass:"mx-auto",attrs:{"max-width":"300","type":"list-item-avatar-two-line@3"}})],1):_c(VList,{attrs:{"dense":""}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v("Nombre")]),_c(VListItemTitle,[_vm._v(_vm._s(_vm.empresa.nombre))])],1)],1),_c(VListItem,[_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v("Nombre corto")]),_c(VListItemTitle,[_vm._v(_vm._s(_vm.empresa.nombreCorto))])],1)],1),_c(VListItem,[_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v("Bucket")]),_c(VListItemTitle,[_vm._v(_vm._s(_vm.empresa.bucket))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }